import {useParams} from "react-router";
import {Header} from "../utils/Header";
import {
    Box,
    Button,
    Card,
    CardContent,
    CardOverflow,
    CircularProgress,
    Container,
    Divider,
    IconButton,
    Snackbar,
    Stack,
    Typography
} from "@mui/joy";
import {useEffect, useState} from "react";
import "firebase/compat/firestore"
import {FUNCTION_ANNULLA_PRENOTAZIONE, FUNCTION_GET_PRENOTAZIONE, FUNCTION_MODIFICA_SLOT} from "../utils/General";
import {getFunctions, httpsCallable} from "firebase/functions";
import {Close} from "@mui/icons-material";
import moment from "moment";
import {Link} from "react-router-dom";


export function Booking() {

    let { id } = useParams();
    const [tab, setTab] = useState(0)
    const [data, setData] = useState(null)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)

    useEffect(() => {
        setLoading(true)
        setError(null)
        const functions = getFunctions();
        const callablefunction = httpsCallable(functions, FUNCTION_GET_PRENOTAZIONE);
        callablefunction({id: id})
            .then((res) => {
                switch (res.data.code) {
                    case 200:
                        setError(null)
                        setLoading(false)
                        setData(res.data.prenotazione)
                        break
                    case 404:
                        setError("Nessuna prenotazione trovata")
                        setLoading(false)
                        break
                    default:
                        setError("Si è verificato un errore")
                        setLoading(false)
                        break
                }
            })
            .catch((err) => {
                setError("Si è verificato un errore")
                setLoading(false)
            })
    }, []);

    return (
        <Box>
            <Header />
            <Container sx={{ display: 'flex', flexDirection:"row", justifyContent:"center", alignItems:"center", minHeight:"90vh" }}>
                {
                    loading ?
                        <CircularProgress />
                        :
                        data!==null ?
                            switchViews()
                            :
                            <Stack sx={{textAlign:"center"}}>
                                <Typography level="h3">
                                    Non abbiamo trovato nessuna prenotazione
                                </Typography>
                                <Typography level="body-sm" sx={{mb:3}}>
                                    Controlla che il codice sia corretto: <span className="span-as-pre">{id}</span>
                                </Typography>
                                <Link to="/">
                                    <Button fullWidth>
                                        Torna indietro
                                    </Button>
                                </Link>
                            </Stack>
                }
            </Container>
            <Snackbar
                autoHideDuration={5000}
                open={error !== null}
                variant="outlined"
                color="danger"
                size="md"
                onClose={() => {
                    setError(null);
                }}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                endDecorator={
                    <IconButton
                        onClick={() => setError(null)}
                        size="sm"
                    >
                        <Close />
                    </IconButton>
                }
            >
                {error}
            </Snackbar>
        </Box>
    )

    function switchViews() {
        switch (tab) {
            // visualizza prenotazione
            case 0:
                return (
                    <Stack direction={{xs:"column", md:"row"}} spacing={5} sx={{ width:"100%", maxWidth:"100vw", display: 'flex', justifyContent:"space-evenly", alignItems:"center" }}>
                        <Stack spacing={3} textAlign={{xs:"center", md:"left"}}>
                            <Typography level="h3">
                                I tuoi dati
                            </Typography>
                            <Stack>
                                <Typography level="body-lg">
                                    Nome: <b>{data.name}</b>
                                </Typography>
                                <Typography level="body-lg">
                                    Cognome: <b>{data.surname}</b>
                                </Typography>
                                <Typography level="body-lg">
                                    Telefono: <b>{data.phone}</b>
                                </Typography>
                                <Typography level="body-lg">
                                    Email: <b>{data.email}</b>
                                </Typography>
                            </Stack>
                        </Stack>
                        <Card
                            size="lg"
                            variant="soft"
                            sx={{ borderRadius: 'xl', minWidth: { xs:"auto", md:"350px" }, }}
                        >
                            <CardContent>
                                <Stack spacing={3}>
                                    <Typography level="h3">La tua prenotazione</Typography>
                                    <Stack>
                                        <Typography level="body">
                                            Data: <b>{moment.unix(data.datetimestart).format("DD/MM/YYYY")}</b>
                                        </Typography>
                                        <Typography level="body">
                                            Ora: <b>{moment.unix(data.datetimestart).format("HH:mm")} - {moment.unix(data.datetimeend).format("HH:mm")}</b>
                                        </Typography>
                                        <Typography level="body">
                                            Barbiere: <b>{data.barber}</b>
                                        </Typography>
                                    </Stack>

                                    {
                                        moment.unix(data.datetimestart).isBefore(moment().add(1, "day").startOf("day")) ?
                                            <Typography level="body-sm" textAlign="center" fontStyle="italic" fontWeight="bold">
                                                La prenotazione non è modificabile
                                            </Typography>
                                            :
                                            <Stack direction={{xs:"column", md:"row"}} spacing={2} sx={{ display: 'flex', justifyContent:"center", alignItems:"center"}}>
                                                <Button
                                                    variant="outlined"
                                                    color="danger"
                                                    fullWidth
                                                    onClick={() => {setTab(2)}}
                                                >
                                                    Annulla prenotazione
                                                </Button>
                                                <Button
                                                    variant="solid"
                                                    color="primary"
                                                    fullWidth
                                                    onClick={() => {setTab(1)}}
                                                >
                                                    Modifica prenotazione
                                                </Button>
                                            </Stack>
                                    }
                                </Stack>
                            </CardContent>
                            <CardOverflow variant="soft" sx={{ bgcolor: 'background.level1' }}>
                                <Divider inset="context" />
                                <CardContent orientation="horizontal" sx={{justifyContent:"end", py:1}}>
                                    <Typography level="body-sm" textAlign="end">
                                        Codice prenotazione: <span className="span-as-pre">{id}</span>
                                    </Typography>
                                </CardContent>
                            </CardOverflow>
                        </Card>
                    </Stack>
                )
            // todo modifica prenotazione
            case 1:
                break
            // annulla prenotazione
            case 2:
                return (
                    <Stack spacing={5} sx={{ width:"100%", textAlign: "center" }}>
                        <Stack spacing={3}>
                            <Stack>
                                <Typography level="h3">
                                    Annullare definitivamente la prenotazione?
                                </Typography>
                                <Typography level="body-sm">
                                    Se annulli la prenotazione, qualcun altro potrà prenotare il tuo turno.
                                </Typography>
                            </Stack>
                            <Stack direction="row" spacing={2} sx={{ display: 'flex', justifyContent:"center", alignItems:"center"}}>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    onClick={() => {setTab(0)}}
                                >
                                    Indietro
                                </Button>
                                <Button
                                    variant="solid"
                                    color="danger"
                                    onClick={() => {
                                        annullaPrenotazione(id)
                                    }}
                                    disabled={loading}
                                >
                                    Annulla prenotazione
                                </Button>
                            </Stack>
                        </Stack>
                    </Stack>
                )
            // todo conferma modifica prenotazione
            case 3:
                break
            // conferma annullamento prenotazione
            case 4:
                return (
                    <Stack sx={{textAlign:"center"}}>
                        <Typography level="h3">
                            La tua prenotazione è stata annullata
                        </Typography>
                        <Typography level="body-sm" sx={{mb:3}}>
                            Riceverai una mail e un messaggio di conferma.
                        </Typography>
                        <Link to="/prenota">
                            <Button fullWidth>
                                Prenota un nuovo appuntamento
                            </Button>
                        </Link>
                    </Stack>
                )
            // errore generico
            default:
                return (
                    <Stack sx={{textAlign:"center"}}>
                        <Typography level="h3">
                            Si è verificato un problema
                        </Typography>
                        <Typography level="body-sm" sx={{mb:3}}>
                            Riprova più tardi.
                        </Typography>
                        <Button fullWidth onClick={() => {window.location.reload()}}>
                            Ricarica la pagina
                        </Button>
                    </Stack>
                )
        }
    }

    function annullaPrenotazione(id) {
        setLoading(true)
        const functions = getFunctions();
        const callablefunction = httpsCallable(functions, FUNCTION_ANNULLA_PRENOTAZIONE);
        callablefunction({ id: id })
            .then((res) => {
                setTab(4)
                setLoading(false)
            })
            .catch((err) => {
                setError("Si è verificato un errore")
                setLoading(false)
            })
    }

    function modificaPrenotazione(id, datetimestart, datetimeend, barber, name, surname, email, phone) {
        const functions = getFunctions();
        const callablefunction = httpsCallable(functions, FUNCTION_MODIFICA_SLOT);
        callablefunction({ id: id, datetimestart: datetimestart, datetimeend: datetimeend, barber: barber, name: name, surname: surname, email: email, phone: phone })
            .then((res) => {
                switch (res.data.code) {
                    case 200:
                        setTab(4)
                        break
                    case 404:
                        setError("Il turno non è più disponibile")
                        break
                    case 409:
                        setError("Qualcuno ha prenotato prima di te")
                        break
                    default:
                        break
                }
            })
            .catch((err) => {
                setError("Si è verificato un errore")
            })
    }
}