import {AspectRatio, Box, Card, Container, IconButton, Stack, Typography} from "@mui/joy";
import {ArrowForward, ContentCut, Event} from "@mui/icons-material";
import {Link} from "react-router-dom";
import {Header} from "../utils/Header";


export function Home() {
    return (
        <Box>
            <Header />
            <Container sx={{ display: 'flex', flexDirection:"row", justifyContent:"center", alignItems:"center", minHeight:"90vh", minWidth:"100%", maxWidth: "100%"}}>
                <Stack spacing={3} direction={{xs:"column", lg:"row"}}>
                    <Link to="/prenota">
                        <Card
                            size="lg"
                            variant="soft"
                            color="success"
                            invertedColors
                            sx={{ maxWidth: 300, boxShadow: 'lg', borderRadius: 'xl', transition: '0.3s',
                                '&:hover': {
                                    textDecoration: 'none',
                                    transform: 'scale(0.95)',
                                    transition: '0.3s'
                                }}}
                        >
                            <AspectRatio
                                data-skip-inverted-colors
                                variant="soft"
                                color="neutral"
                                ratio="1"
                                sx={{ width: 48 }}
                            >
                                <div>
                                    <ContentCut />
                                </div>
                            </AspectRatio>

                            <Typography level="h3">Prenota</Typography>
                            <Typography level="body-sm">
                                Prenota adesso il tuo taglio. Scegli il tipo di servizio, il tuo barbiere di fiducia e la data che preferisci.
                            </Typography>

                            <IconButton
                                variant="plain"
                                size="lg"
                                sx={{ alignSelf: 'flex-end', borderRadius: '50%', mr: -1, my: -1 }}
                            >
                                <ArrowForward />
                            </IconButton>
                        </Card>
                    </Link>
                    <Link to="/gestisci-prenotazione">
                        <Card
                            size="lg"
                            variant="soft"
                            color="danger"
                            invertedColors
                            sx={{ maxWidth: 300, boxShadow: 'lg', borderRadius: 'xl', transition: '0.3s',
                                '&:hover': {
                                    textDecoration: 'none',
                                    transform: 'scale(0.95)',
                                    transition: '0.3s'
                                }}}
                        >
                            <AspectRatio
                                data-skip-inverted-colors
                                variant="soft"
                                color="neutral"
                                ratio="1"
                                sx={{ width: 48 }}
                            >
                                <div>
                                    <Event />
                                </div>
                            </AspectRatio>

                            <Typography level="h3">Gestisci prenotazione</Typography>
                            <Typography level="body-sm">
                                Nessun problema! Puoi scegliere un giorno o un orario diverso, oppure puoi annullare la tua prenotazione.
                            </Typography>

                            <IconButton
                                variant="plain"
                                size="lg"
                                sx={{ alignSelf: 'flex-end', borderRadius: '50%', mr: -1, my: -1 }}
                            >
                                <ArrowForward />
                            </IconButton>
                        </Card>
                    </Link>
                </Stack>
            </Container>
        </Box>
    )
}