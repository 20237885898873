import {Header} from "../utils/Header";
import {Box, Button, Container, FormControl, FormHelperText, FormLabel, Input, Stack} from "@mui/joy";
import {useState} from "react";
import {Link} from "react-router-dom";


export function GestisciPrenotazione() {

    const [code, setCode] = useState("");

    return (
        <Box>
            <Header />
            <Container sx={{ display: 'flex', flexDirection:"row", justifyContent:"center", alignItems:"center", minHeight:"90vh" }}>
                <Stack spacing={3} direction="column">
                    <FormControl>
                        <FormLabel>Codice prenotazione</FormLabel>
                        <Input type="text" placeholder="XXXXXXXXXX"
                               value={code}
                               onChange={e => setCode(e.target.value)}
                               slotProps={{
                                   input: {
                                       maxLength: 10
                                   },
                               }}
                               className="span-as-pre"
                        />
                        <FormHelperText>
                            Il codice contiene 10 caratteri alfanumerici
                        </FormHelperText>
                    </FormControl>
                    <Stack direction="row" spacing={2} sx={{ display: 'flex', justifyContent:"center", alignItems:"center"}}>
                        <Link to="/">
                            <Button
                                variant="outlined"
                                color="neutral"
                                fullWidth
                            >
                                Indietro
                            </Button>
                        </Link>
                        <Button
                            variant="solid"
                            fullWidth
                            disabled={code.length !== 10}
                            onClick={() => {
                                window.location.href = "/b/" + code
                            }}
                        >
                            Cerca prenotazione
                        </Button>
                    </Stack>

                </Stack>
            </Container>
        </Box>
    )
}