import {
    Box,
    Button,
    Card,
    CardContent,
    CircularProgress,
    Container,
    FormControl,
    FormLabel,
    Grid,
    IconButton,
    Input,
    Option,
    Select,
    Snackbar,
    Stack,
    Step,
    stepClasses,
    StepIndicator,
    stepIndicatorClasses,
    Stepper,
    Typography
} from "@mui/joy";
import {CheckCircle, Close, ConfirmationNumber, ContentCut, Event, Info} from "@mui/icons-material";
import {Link} from "react-router-dom";
import {Header} from "../utils/Header";
import {useState} from "react";
import {getFunctions, httpsCallable} from "firebase/functions";
import moment from "moment";
import {FUNCTION_PRENOTA_SLOT, FUNCTION_SLOT_DISPONIBILI, isValidEmail, isValidPhoneNumber} from "../utils/General";


export function Prenota() {

    const [tab, setTab] = useState(0)
    const [duration, setDuration] = useState(null)
    const [barber, setBarber] = useState(null)
    const [slotLiberi, setSlotLiberi] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [selectedSlot, setSelectedSlot] = useState(null);
    const [cliente, setCliente] = useState({
        name: null,
        surname: null,
        email: null,
        phone: null,
    });
    const [result, setResult] = useState(null);

    return (
        <Box>
            <Header/>
            <Container sx={{
                display: 'flex',
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "start",
                minHeight: "90vh",
                p: 5
            }}>
                <Stack spacing={3} direction="column" sx={{width: "100%", maxWidth:"100vw", alignItems: "center"}}>
                    <StepperPrenota tab={tab}/>
                    {switchTabs()}
                </Stack>
            </Container>
            <Snackbar
                autoHideDuration={5000}
                open={error !== null}
                variant="outlined"
                color="danger"
                size="md"
                onClose={() => {
                    setError(null);
                }}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                endDecorator={
                    <IconButton
                        onClick={() => setError(null)}
                        size="sm"
                    >
                        <Close />
                    </IconButton>
                }
            >
                {error}
            </Snackbar>
        </Box>
    )

    function switchTabs() {
        switch (tab) {
            case 0:
                return (
                    <Stack spacing={2} sx={{alignItems: "center", width: 300}}>
                        <Stack textAlign="center">
                            <Typography level="h3">Tipologia servizio</Typography>
                            <Typography level="body-sm">Scegli il servizio e il barbiere che desideri</Typography>
                        </Stack>
                        <FormControl sx={{width: "100%"}}>
                            <FormLabel>Tipologia taglio</FormLabel>
                            <Select placeholder="Scegli…"
                                    value={duration}
                                    onChange={(e, newValue) => setDuration(newValue)}
                            >
                                <Option value={30}>30 minuti</Option>
                                <Option value={60}>60 minuti</Option>
                                <Option value={90}>90 minuti</Option>
                            </Select>
                        </FormControl>
                        <FormControl sx={{width: "100%"}}>
                            <FormLabel>Barbiere</FormLabel>
                            <Select placeholder="Scegli…"
                                    value={barber}
                                    onChange={(e, newValue) => setBarber(newValue)}
                            >
                                <Option value="Andrea">Andrea</Option>
                                <Option value="Mirko">Mirko</Option>
                            </Select>
                        </FormControl>
                        <Stack direction="row" spacing={2}
                               sx={{width: "100%", display: 'flex', justifyContent: "center", alignItems: "center"}}>
                            <Link to="/">
                                <Button
                                    variant="outlined"
                                    color="neutral"
                                    fullWidth
                                >
                                    Annulla
                                </Button>
                            </Link>
                            <Button
                                variant="solid"
                                color="primary"
                                fullWidth
                                onClick={() => {
                                    setTab(1)
                                    getSlotLiberi()
                                }}
                                disabled={!duration || !barber}
                            >
                                Avanti
                            </Button>
                        </Stack>
                    </Stack>
                )
            case 1:
                return (
                    <Stack spacing={3} sx={{justifyContent:"center", alignItems: "center"}}>
                        {
                            loading ?
                                <CircularProgress/>
                                :
                                slotLiberi !== null ?
                                    <>
                                        <Stack textAlign="center">
                                            <Typography level="h3">Data e ora dell'appuntamento</Typography>
                                            <Typography level="body-sm">Scegli uno slot disponibile</Typography>
                                        </Stack>
                                        <Stack direction="row" spacing={3} sx={{maxWidth: {xs:"95vw", lg:"80vw"}, overflowX:"auto", scrollbarWidth: "thin", scrollbarColor: "lightgray transparent"}}>
                                            {
                                                Object.keys(slotLiberi).map(k =>
                                                    <Stack key={k} spacing={2} sx={{
                                                        minWidth: {
                                                            xs: "calc(100% / 3)",
                                                            md: "calc(100% / 7)",
                                                        }
                                                    }}>
                                                        <Typography level="body-lg" fontWeight="bold" textAlign="center">
                                                            {moment.unix(k).format("DD/MM")}
                                                        </Typography>
                                                        <Stack spacing={1} sx={{maxHeight: {xs:"60vh", md:"50vh"}, pl: 1}}
                                                               className="scrollbarOrari">
                                                            {
                                                                slotLiberi[k].map(s =>
                                                                    <Button key={s.datetimestart}
                                                                            variant={selectedSlot === s ? "solid" : "outlined"}
                                                                            size="sm"
                                                                            onClick={() => setSelectedSlot(s)}
                                                                    >
                                                                        {moment.unix(s.datetimestart).format("HH:mm")}
                                                                    </Button>
                                                                )
                                                            }
                                                        </Stack>
                                                    </Stack>
                                                )
                                            }
                                        </Stack>

                                        <Container spacing={2} sx={{
                                            width: "100%",
                                            display: 'flex',
                                            flexDirection: "row",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            mx:3,
                                        }}>
                                            <Button
                                                variant="outlined"
                                                color="neutral"
                                                fullWidth
                                                onClick={() => {
                                                    setTab(0)
                                                    setSelectedSlot(null)
                                                }}
                                                sx={{mr:1}}
                                            >
                                                Indietro
                                            </Button>
                                            <Button
                                                variant="solid"
                                                color="primary"
                                                fullWidth
                                                onClick={() => {
                                                    setTab(2)
                                                }}
                                                disabled={!selectedSlot}
                                                sx={{ml:1}}
                                            >
                                                Avanti
                                            </Button>
                                        </Container>
                                    </>
                                    :
                                    <Stack sx={{textAlign: "center"}}>
                                        <Typography level="h3">
                                            Si è verificato un problema
                                        </Typography>
                                        <Typography level="body-sm" sx={{mb: 3}}>
                                            Riprova più tardi.
                                        </Typography>
                                        <Button fullWidth onClick={() => {
                                            window.location.reload()
                                        }}>
                                            Ricarica la pagina
                                        </Button>
                                    </Stack>
                        }
                    </Stack>
                )
            case 2:
                return (
                    <Stack spacing={3}>
                        <Stack textAlign="center">
                            <Typography level="h3">I tuoi dati</Typography>
                            <Typography level="body-sm">Compila i campi con i tuoi dati</Typography>
                        </Stack>
                        <Grid container spacing={2}>
                            <Grid xs={12} md={6}>
                                <FormControl sx={{width: "100%"}}>
                                    <FormLabel>Nome</FormLabel>
                                    <Input type="text"
                                           placeholder="Mario"
                                           value={cliente.name}
                                           onChange={e => setCliente({...cliente, name: e.target.value})}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid xs={12} md={6}>
                                <FormControl sx={{width: "100%"}}>
                                    <FormLabel>Cognome</FormLabel>
                                    <Input type="text"
                                           placeholder="Rossi"
                                           value={cliente.surname}
                                           onChange={e => setCliente({...cliente, surname: e.target.value})}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid xs={12} md={6}>
                                <FormControl sx={{width: "100%"}}>
                                    <FormLabel>Email</FormLabel>
                                    <Input type="email"
                                           placeholder="nome@email.com"
                                           value={cliente.email}
                                           onChange={e => setCliente({...cliente, email: e.target.value})}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid xs={12} md={6}>
                                <FormControl sx={{width: "100%"}}>
                                    <FormLabel>Telefono</FormLabel>
                                    <Input type="tel"
                                           placeholder="3330000000"
                                           value={cliente.phone}
                                           onChange={e => setCliente({...cliente, phone: e.target.value})}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Stack direction="row" spacing={2} sx={{
                            width: "100%",
                            display: 'flex',
                            justifyContent: "center",
                            alignItems: "center"
                        }}>
                            <Button
                                variant="outlined"
                                color="neutral"
                                fullWidth
                                onClick={() => {
                                    setTab(1)
                                }}
                            >
                                Indietro
                            </Button>
                            <Button
                                variant="solid"
                                color="primary"
                                fullWidth
                                onClick={() => {
                                    if(!isValidEmail(cliente.email)) { setError("Email non valida") }
                                    else if(!isValidPhoneNumber(cliente.phone)) { setError("Numero di telefono non valido") }
                                    else setTab(3)
                                }}
                                disabled={!cliente.name || !cliente.surname || !cliente.email || !cliente.phone}
                            >
                                Avanti
                            </Button>
                        </Stack>
                    </Stack>
                )
            case 3:
                return (
                    <Stack spacing={3}>
                        <Stack textAlign="center">
                            <Typography level="h3">Riepilogo</Typography>
                            <Typography level="body-sm">Controlla i dati inseriti e conferma la prenotazione</Typography>
                        </Stack>
                        <Card
                            size="lg"
                            variant="soft"
                            sx={{
                                borderRadius: 'xl',
                                minWidth: { xs:"auto", md:"350px" },
                        }}
                        >
                            <CardContent>
                                <Stack spacing={2}>
                                    <Stack spacing={1}>
                                        <Typography level="h3">La tua prenotazione</Typography>
                                        <Stack>
                                            <Typography level="body">
                                                Data: <b>{moment.unix(selectedSlot.datetimestart).format("DD/MM/YYYY")}</b>
                                            </Typography>
                                            <Typography level="body">
                                                Ora: <b>{moment.unix(selectedSlot.datetimestart).format("HH:mm")} - {moment.unix(selectedSlot.datetimeend).format("HH:mm")}</b>
                                            </Typography>
                                            <Typography level="body">
                                                Barbiere: <b>{selectedSlot.barber}</b>
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                    <Stack spacing={1}>
                                        <Typography level="h3">I tuoi dati</Typography>
                                        <Stack>
                                            <Typography level="body">Nome: <b>{cliente.name}</b></Typography>
                                            <Typography level="body">Cognome: <b>{cliente.surname}</b></Typography>
                                            <Typography level="body">Email: <b>{cliente.email}</b></Typography>
                                            <Typography level="body">Telefono: <b>{cliente.phone}</b></Typography>
                                        </Stack>
                                    </Stack>
                                </Stack>
                            </CardContent>
                        </Card>
                        <Stack direction="row" spacing={2} sx={{
                            width: "100%",
                            display: 'flex',
                            justifyContent: "center",
                            alignItems: "center"
                        }}>
                            <Button
                                variant="outlined"
                                color="neutral"
                                fullWidth
                                onClick={() => {
                                    setTab(2)
                                }}
                            >
                                Indietro
                            </Button>
                            <Button
                                variant="solid"
                                color="primary"
                                fullWidth
                                disabled={loading}
                                onClick={() => {
                                    prenotaSlot()
                                }}
                            >
                                Prenota
                            </Button>
                        </Stack>
                    </Stack>
                )
            case 4:
                return (
                    <Stack spacing={3}>
                        <Stack textAlign="center">
                            <Typography level="h3">Prenotazione confermata</Typography>
                            <Typography level="body-sm">Riceverai una mail e un messaggio di conferma</Typography>
                        </Stack>
                        <Link to={"/b/" + result.id}>
                            <Button
                                variant="solid"
                                color="primary"
                                fullWidth
                            >
                                Gestisci la prenotazione
                            </Button>
                        </Link>
                    </Stack>
                )
            default:
                return (
                    <Stack sx={{textAlign:"center"}}>
                        <Typography level="h3">
                            Si è verificato un problema
                        </Typography>
                        <Typography level="body-sm" sx={{mb:3}}>
                            Riprova più tardi.
                        </Typography>
                        <Button fullWidth onClick={() => {window.location.reload()}}>
                            Ricarica la pagina
                        </Button>
                    </Stack>
                )
        }
    }

    function getSlotLiberi() {
        setLoading(true)
        const functions = getFunctions();
        const callablefunction = httpsCallable(functions, FUNCTION_SLOT_DISPONIBILI);
        callablefunction({duration: duration, barber: barber})
            .then((res) => {
                switch (res.data.code) {
                    case 200:
                        const groupedEvents = groupByDate(res.data.slots);
                        setSlotLiberi(groupedEvents)
                        setLoading(false)
                        break
                    case 404:
                        setError("Nessun turno disponibile per il barbiere specificato")
                        setLoading(false)
                        break
                    default:
                        setError("Si è verificato un errore")
                        setLoading(false)
                        break
                }
            })
            .catch((err) => {
                setError("Si è verificato un errore")
                setLoading(false)
            })
    }

    function prenotaSlot() {
        setLoading(true)
        const functions = getFunctions();
        const callablefunction = httpsCallable(functions, FUNCTION_PRENOTA_SLOT);
        callablefunction({
            datetimestart: selectedSlot.datetimestart,
            datetimeend: selectedSlot.datetimeend,
            barber: barber,
            name: cliente.name,
            surname: cliente.surname,
            email: cliente.email,
            phone: cliente.phone,
        })
            .then((res) => {
                switch (res.data.code) {
                    case 200:
                        setResult(res.data.appointment)
                        setTab(4)
                        setLoading(false)
                        break
                    case 404:
                        setError("Il turno non è più disponibile")
                        setLoading(false)
                        break
                    case 409:
                        setError("Qualcuno ha prenotato prima di te")
                        setLoading(false)
                        break
                    default:
                        setError("Si è verificato un errore")
                        setLoading(false)
                        break
                }
            })
            .catch((err) => {
                setError("Si è verificato un errore")
                setLoading(false)
            })
    }

    function groupByDate(appointments) {
        return appointments.reduce((acc, appointment) => {
            const date = moment.unix(appointment.datetimestart).startOf("day").unix();
            if (!acc[date]) {
                acc[date] = [];
            }
            acc[date].push(appointment);
            return acc;
        }, {});
    }

    function StepperPrenota({tab}) {
        return (
            <Stepper
                size="lg"
                sx={{
                    width: '100%',
                    '--StepIndicator-size': {xs:"3rem", md:'3rem'},
                    '--Step-connectorInset': '0px',
                    [`& .${stepIndicatorClasses.root}`]: {
                        borderWidth: {xs:3, md:4},
                    },
                    [`& .${stepClasses.root}::after`]: {
                        height: {xs:3, md:4},
                    },
                    [`& .${stepClasses.completed}`]: {
                        [`& .${stepIndicatorClasses.root}`]: {
                            borderColor: 'primary.300',
                            color: 'primary.300',
                        },
                        '&::after': {
                            bgcolor: 'primary.300',
                        },
                    },
                    [`& .${stepClasses.active}`]: {
                        [`& .${stepIndicatorClasses.root}`]: {
                            borderColor: 'currentColor',
                        },
                    },
                    [`& .${stepClasses.disabled} *`]: {
                        color: 'neutral.outlinedDisabledColor',
                    },
                }}
            >
                <StepPrenota id={0} tab={tab} icon={<ContentCut/>} label="Tipologia servizio"/>
                <StepPrenota id={1} tab={tab} icon={<Event/>} label="Data appuntamento"/>
                <StepPrenota id={2} tab={tab} icon={<Info/>} label="I tuoi dati"/>
                <StepPrenota id={3} tab={tab} icon={<CheckCircle/>} label="Riepilogo"/>
                <StepPrenota id={4} tab={tab} icon={<ConfirmationNumber/>} label="Prenotazione"/>
                {
                    /*
                        <Step
                            orientation="vertical"
                            active
                            indicator={
                                <StepIndicator variant="outlined" color="neutral">
                                    <CheckCircle />
                                </StepIndicator>
                            }
                        />
                     */
                }
            </Stepper>
        )
    }

    function StepPrenota({id, tab, icon, label}) {
        const active = tab === id
        const completed = tab > id
        const disabled = tab < id

        return (
            <Step
                orientation="vertical"
                active={active}
                completed={completed}
                disabled={disabled}
                indicator={
                    <StepIndicator variant={active ? "solid" : "outlined"} color={disabled ? "neutral" : "primary"}>
                        {icon}
                    </StepIndicator>
                }
            >
                {
                    active ?
                        <Typography
                            sx={{
                                textTransform: 'uppercase',
                                fontWeight: 'lg',
                                fontSize: '0.75rem',
                                letterSpacing: '0.5px',
                                display: {
                                    xs: "none",
                                    md: "block"
                                }
                            }}
                        >
                            {label}
                        </Typography>
                        :
                        null
                }
            </Step>
        )
    }
}