import {Box, Container, Sheet, Stack, Typography} from "@mui/joy";
import {Link} from "react-router-dom";
import {LOGO, LOGO_SCRITTA} from "./General";


export function Header() {

    return (
        <Sheet sx={{maxWidth: "100%", px:5, py:2}}>
            <Container sx={{
                display: "flex",
                flexDirection: {xs:"column", md:'row'},
                justifyContent: {xs:"center", md:'space-between'},
                alignItems: 'center',
                width: '100%',
            }}>
                <Box sx={{mb:{xs:2, md:0}}}>
                    <a href="https://www.scalpsquadbarberia.it/">
                        <Stack direction="row" spacing={3} sx={{justifyContent:"center", alignItems:"center"}}>
                            <img
                                src={LOGO}
                                height={40}
                                alt="Logo"
                            />
                            <img
                                src={LOGO_SCRITTA}
                                height={40}
                                alt="Logo"
                            />
                        </Stack>
                    </a>
                </Box>

                <Stack direction="row" spacing={3} fullWidth>
                    <Link to="/prenota">
                        <Typography>Prenota</Typography>
                    </Link>
                    <Link to="/gestisci-prenotazione">
                        <Typography>Gestisci prenotazione</Typography>
                    </Link>
                </Stack>
            </Container>
        </Sheet>
    )
}